import React from 'react';
import cx from 'classnames';
import { ISocialProofBarFields } from '../../../types/contentful';

interface Props extends ISocialProofBarFields {
  includeFullWidth?: boolean;
}

export function SocialProofBar({
  textColor,
  headline1,
  headline2,
  headline3,
  headline4,
  subheadline1,
  subheadline2,
  subheadline3,
  subheadline4,
  includeFullWidth = false
}: Props) {
  const headlineClassNames = cx('text-2xl font-bold leading-none', {
    'text-spearmint': textColor === 'Spearmint',
    'text-green': textColor === 'Green',
    'text-ginger': textColor === 'Ginger',
    'text-cinnamint': textColor === 'Cinnamint',
    'text-blackberry': textColor === 'Blackberry',
    'text-navy': textColor === 'Sweetmint',
    'text-white': textColor === 'White',
    'text-muted-navy': textColor === 'Muted Navy'
  });
  const headlines = [headline1, headline2, headline3, headline4];
  const subheadlines = [subheadline1, subheadline2, subheadline3, subheadline4];

  return (
    <section
      className={cx(
        'flex flex-wrap items-start justify-center bg-[#DBF6F1] py-9 tablet:gap-7 desktop:gap-10',
        { 'mt-6 w-full ': !!includeFullWidth }
      )}
      data-testid="social-proof-bar"
    >
      {headlines.map(
        (headline, index) =>
          headline && (
            <div
              key={headline}
              className="w-1/2 shrink-0 p-4 tablet:w-auto tablet:max-w-[160px] tablet:shrink tablet:p-0"
              data-testid="social-proof-bar-item"
            >
              <div
                className={headlineClassNames}
                data-testid="social-proof-bar-item-headline"
              >
                {headline}
              </div>
              {subheadlines[index] && (
                <div
                  className="pt-3 text-sm font-light"
                  data-testid="social-proof-bar-item-subheadline"
                >
                  {subheadlines[index]}
                </div>
              )}
            </div>
          )
      )}
    </section>
  );
}
