import React, { useEffect, useMemo } from 'react';

import { IDynamicText } from '@/types/contentful';

import { trackWithFingerprint } from '../../utils/trackWithFingerprint';
import { validateParam } from '../../utils/regex';

import { TextProps } from './index';
import { StyledText } from './styles';
import { usePageDataContext } from '../../context/PageData';
import { addAdditionalDataToDynamicText } from '../../utils/addAdditionalDataToDynamicText';

interface ITextWithOptions extends TextProps {
  keyword: string;
  channel?: string;
  fallback: string;
  options?: IDynamicText[];
  module?: string;
}

export function TextWithOptions({
  keyword,
  channel,
  fallback,
  options,
  module,
  ...props
}: ITextWithOptions) {
  const { advocateUserData, query } = usePageDataContext();
  // Find text variation by ad keyword and utm channel
  const value = useMemo(() => {
    const variation = options?.find(
      ({ fields }: IDynamicText) =>
        fields.keyword === keyword &&
        fields.channels.includes(channel as string) &&
        validateParam(keyword)
    );

    return addAdditionalDataToDynamicText(
      variation?.fields?.text,
      variation?.fields?.keyGeneratedText,
      advocateUserData,
      fallback,
      query
    );
  }, [keyword, channel, options]);

  useEffect(() => {
    if (keyword && channel !== 'organic' && options?.length && !value) {
      trackWithFingerprint('Dynamic Text Failed', {
        module,
        keyword,
        channel,
        allowedOptions: options?.map(({ fields }) => ({
          keyword: fields?.keyword,
          channels: fields?.channels
        }))
      });
    }
  }, [keyword, channel, options, value]);

  return <StyledText {...props}>{value ?? fallback}</StyledText>;
}
